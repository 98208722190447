<template>
  <form v-if="studentObject && applicantObject">
    <!-- start card -->
    <v-row class="my-5">
      <v-col cols="12" sm="12" class="first-info">
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h3 id="info-header" class="info-header">
                      {{ $t("General Information") }}
                    </h3>
                  </v-col>
                  <v-col cols="12" sm="4" class="text-center" id="logo-div">
                    <div class="logo-div-img">
                      <img
                        style="width: 80px; height: 80px"
                        v-if="studentObject.image != null"
                        id="logo"
                        class="rounded-circle"
                        :src="studentObject.image"
                        alt=""
                      />
                      <img
                        v-if="
                          studentObject.image == null ||
                          studentObject.image == ''
                        "
                        id="logo"
                        class="rounded-circle"
                        src="../../assets/avatar.png"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" sm="8" id="info-div" class="info-div">
                    <div class="viewData">
                      <v-row>
                        <v-col xs="5" md="5">
                          <p>
                            <strong> {{ $t("Student Code") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="7" md="7" class="">
                          <p class="value">
                            {{ applicantObject.application_number }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col xs="5" md="5">
                          <p>
                            <strong> {{ $t("Fee Plan") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="7" md="7" class="">
                          <p class="value">
                            {{ applicantObject.feeplan }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col xs="5" md="5">
                          <p>
                            <strong> {{ $t("Name") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="7" md="7" class="">
                          <p class="value">
                            {{ applicantObject.firstname }}
                            {{ applicantObject.fathername }}
                            {{ applicantObject.familyname }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col xs="5" md="5">
                          <p>
                            <strong>{{ $t("Birthdate Gregorian") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="7" md="7" class="">
                          <p class="value">
                            {{ applicantObject.date_of_birth }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col xs="5" md="5">
                          <p>
                            <strong>{{ $t("Birthdate Hijri") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="7" md="7" class="">
                          <p class="value">
                            {{ applicantObject.date_of_birth_hijri }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col xs="5" md="5">
                          <p>
                            <strong>{{ $t("Place Of Birth") }}: </strong>
                          </p>
                        </v-col>
                        <v-col xs="7" md="7" class="">
                          <p class="value">
                            {{ applicantObject.place_of_birth }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col xs="5" md="5">
                          <p>
                            <strong>{{ $t("Gender") }}: </strong>
                          </p>
                        </v-col>
                        <v-col xs="7" md="7" class="">
                          <p class="value" v-if="applicantObject.gender">
                            {{
                              applicantObject.gender.charAt(0).toUpperCase() +
                              applicantObject.gender.slice(1)
                            }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col xs="5" md="5">
                          <p>
                            <strong>{{ $t("Nationality") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="7" md="7" class="">
                          <p class="value">
                            {{ applicantObject.nationality }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-row v-if="applicantObject.national_id">
                        <v-col xs="5" md="5">
                          <p>
                            <strong>{{ $t("National ID") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="7" md="7" class="">
                          <p class="value">
                            {{ applicantObject.national_id }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row v-if="applicantObject.passport_no">
                        <v-col xs="5" md="5">
                          <p>
                            <strong>{{ $t("Passport Number") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="7" md="7" class="">
                          <p class="value">
                            {{ applicantObject.passport_no }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row v-if="applicantObject.iqama_no">
                        <v-col xs="5" md="5">
                          <p>
                            <strong>{{ $t("Iqama Number") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="7" md="7" class="">
                          <p class="value">
                            {{ applicantObject.iqama_no }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row v-if="applicantObject.iqama_expiry_date">
                        <v-col xs="5" md="5">
                          <p>
                            <strong>{{ $t("Iqama Expiry Date") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="7" md="7" class="">
                          <p class="value">
                            {{ applicantObject.iqama_expiry_date }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col xs="5" md="5">
                          <p>
                            <strong>{{ $t("Religion") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="7" md="7" class="">
                          <p class="value capital">
                            {{ applicantObject.religion }}
                          </p>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- endcard -->
    <v-row class="my-5">
      <v-col cols="12" sm="6" class="first-info">
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h3 id="info-header" class="info-header">
                  {{ $t("Technical Details") }}
                </h3>
              </v-col>
              <v-col cols="12" sm="12" id="info-div" class="info-div">
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Date of Enrollment") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.date_of_enrollment }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Previous School") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ applicantObject.previous_school }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Native Language") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ applicantObject.native_language }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Second Language") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ applicantObject.second_language }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong>{{ $t("Grade Level") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.grade_level }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong>{{ $t("Curriculum") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ applicantObject.curriculm }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" class="first-info">
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <h3 id="info-header" class="info-header">
                  {{ $t("Contact Person Details") }}
                </h3>
              </v-col>
              <v-col cols="12" sm="12" id="info-div" class="info-div">
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Name") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ applicantObject.contact_person.name }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Mobile") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ applicantObject.country_code_name
                      }}{{ applicantObject.contact_person.mobile }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Email") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ applicantObject.contact_person.email }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Relationship") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p
                      class="value"
                      v-if="
                        applicantObject.contact_person.relation_ship != 'other'
                      "
                    >
                      {{ applicantObject.contact_person.relation_ship }}
                    </p>
                    <p
                      class="value"
                      v-if="
                        applicantObject.contact_person.relation_ship == 'other'
                      "
                    >
                      {{ applicantObject.contact_person.relation_ship_other }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong>{{ $t("Communication Method") }} :</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value capital">
                      {{ applicantObject.contact_person.communication_method }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- endcard -->
    <v-row class="my-5">
      <v-col
        cols="12"
        sm="6"
        class="first-info"
        v-if="studentObject.mother.length != 0"
      >
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <h3 id="info-header" class="info-header">
                  {{ $t("Mother Details") }}
                  <v-btn
                    v-if="studentObject.mother.user_id"
                    :title="$t('Login as mother')"
                    @click="loginAsUser(studentObject.mother.user_id)"
                    icon
                  >
                    <v-icon>mdi-login</v-icon>
                  </v-btn>
                </h3>
              </v-col>
              <v-col cols="12" sm="12" id="info-div" class="info-div">
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("First Name") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.mother.firstname }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Middle Name") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.mother.middlename }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Family Name") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.mother.familyname }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong>{{ $t("Nationality") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.mother.nationality }}
                    </p>
                  </v-col>
                </v-row>
                <v-row v-if="mother_saudi">
                  <v-col xs="5" md="5">
                    <p>
                      <strong>{{ $t("National ID") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.mother.national_id }}
                    </p>
                  </v-col>
                </v-row>
                <v-row v-if="!mother_saudi">
                  <v-col xs="5" md="5">
                    <p>
                      <strong>{{ $t("Iqama No") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.mother.parent_iqama_no }}
                    </p>
                  </v-col>
                </v-row>
                <v-row v-if="!mother_saudi">
                  <v-col xs="5" md="5">
                    <p>
                      <strong>{{ $t("Expiry Date") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.mother.parent_iqama_no_expire_date }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Education Level") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.mother.education_level }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Occupation") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.mother.occupation }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Business Address") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.mother.business_address }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Home Tel. Number") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.mother.tel }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Mobile Number") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.mother.country_code
                      }}{{ studentObject.mother.mobile }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Email") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.mother.email }}
                    </p>
                  </v-col>
                </v-row>
                <v-row v-if="studentObject.parents_relationship != null">
                  <v-col xs="7" md="7">
                    <p>
                      <strong>{{ $t("Parents Relationship") }} </strong>
                    </p></v-col
                  >
                  <v-col xs="3" md="3">
                    <p class="capital">
                      {{
                        studentObject.parents_relationship != "other"
                          ? studentObject.parents_relationship
                          : studentObject.parents_relationship_other
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="first-info"
        v-if="studentObject.father.length != 0"
      >
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h3 id="info-header" class="info-header">
                  {{ $t("Father Details") }}
                  <v-btn
                    v-if="studentObject.father.user_id"
                    :title="$t('Login as father')"
                    @click="loginAsUser(studentObject.father.user_id)"
                    icon
                  >
                    <v-icon>mdi-login</v-icon>
                  </v-btn>
                </h3>
              </v-col>
              <v-col cols="12" sm="12" id="info-div" class="info-div">
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("First Name") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.father.firstname }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong>{{ $t("Middle Name") }} :</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.father.middlename }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Family Name") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.father.familyname }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong>{{ $t("Nationality") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.father.nationality }}
                    </p>
                  </v-col>
                </v-row>
                <v-row v-if="father_saudi">
                  <v-col xs="5" md="5">
                    <p>
                      <strong>{{ $t("National ID") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.father.national_id }}
                    </p>
                  </v-col>
                </v-row>
                <v-row v-if="!father_saudi">
                  <v-col xs="5" md="5">
                    <p>
                      <strong>{{ $t("Iqama No") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.father.parent_iqama_no }}
                    </p>
                  </v-col>
                </v-row>
                <v-row v-if="!father_saudi">
                  <v-col xs="5" md="5">
                    <p>
                      <strong>{{ $t("Expiry Date") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.father.parent_iqama_no_expire_date }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Education Level") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.father.education_level }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Occupation") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.father.occupation }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Business Address") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.father.business_address }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Home Tel. Number") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.father.tel }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Mobile Number") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.father.country_code
                      }}{{ studentObject.father.mobile }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong>{{ $t("Email") }} :</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ studentObject.father.email }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- endcard -->
    <v-row class="my-5">
      <v-col
        cols="12"
        sm="6"
        class="first-info"
        v-for="(sibling, index) in studentObject.siblings"
        :key="index"
      >
        <v-card class="mx-auto" shaped v-if="sibling.name != ''">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h3 id="info-header" class="info-header">
                  {{ $t("Sibling") }} {{ parseInt(index + 1) }}
                </h3>
              </v-col>
              <v-col cols="12" sm="12" id="info-div" class="info-div">
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Name") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ sibling.name }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Gender") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ sibling.gender }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Mobile Number") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ sibling.country_code }}{{ sibling.mobile }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("Date of Birth") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ sibling.date_of_birth }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="5" md="5">
                    <p>
                      <strong> {{ $t("School") }}:</strong>
                    </p>
                  </v-col>
                  <v-col xs="7" md="7" class="">
                    <p class="value">
                      {{ sibling.school }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- endcard -->
    <v-row class="my-5">
      <v-col
        cols="12"
        sm="12"
        class="first-info"
        v-if="studentObject.academic_history.length > 0"
      >
        <v-card
          class="mx-auto"
          shaped
          v-if="studentObject.academic_history[0].school_name != ''"
        >
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h3 id="info-header" class="info-header">
                      {{ $t("Academic History") }}
                    </h3>
                  </v-col>
                  <v-col cols="12" sm="12" id="info-div" class="info-div">
                    <div
                      class="academic_history_container"
                      v-for="(
                        academic_history, index
                      ) in studentObject.academic_history"
                      :key="index"
                    >
                      <v-row>
                        <v-col xs="3" md="3">
                          <p>
                            <strong> {{ $t("School Name") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="9" md="9" class="">
                          <p class="value">
                            {{ academic_history.school_name }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col xs="3" md="3">
                          <p>
                            <strong> {{ $t("City") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="9" md="9" class="">
                          <p class="value">
                            {{ academic_history.city }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col xs="3" md="3">
                          <p>
                            <strong> {{ $t("Level Completed") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="9" md="9" class="">
                          <p class="value">
                            {{
                              academic_history.completed_level
                                ? academic_history.completed_level.grade_name
                                : ""
                            }}
                          </p>
                        </v-col>
                      </v-row>
                      <!-- <hr /> -->
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row>
          <v-col
            xs="6"
            md="6"
            class=""
            v-if="studentObject.repeated_grade == 1"
          >
            <v-col cols="12" xs="12" md="12">
              <v-checkbox
                dir="rtl"
                :label="$i18n.t('The student repeated a grade in school')"
                class="mx-2"
                v-model="studentObject.repeated_grade"
                disabled
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="12" style="margin-top: -15px !important">
              <v-text-field
                v-model="studentObject.repeated_grade_name"
                @focus="onFocus()"
                dense
                autocomplete="off"
                solo
                v-if="studentObject.repeated_grade"
                disabled
              ></v-text-field>
            </v-col>
          </v-col>
          <v-col
            xs="6"
            md="6"
            class=""
            v-if="studentObject.applicant_expelled == 1"
          >
            <v-col cols="12" xs="12" md="12">
              <v-checkbox
                dir="rtl"
                :label="
                  $i18n.t(
                    'The student has been expelled or asked to leave school'
                  )
                "
                class="mx-2"
                v-model="studentObject.applicant_expelled"
                disabled
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="12" style="margin-top: -15px !important">
              <v-text-field
                v-model="studentObject.applicant_expelled_details"
                @focus="onFocus()"
                dense
                autocomplete="off"
                solo
                v-if="studentObject.applicant_expelled"
                disabled
              ></v-text-field>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- endcard -->
    <v-row
      class="my-5"
      v-if="
        studentObject.communicable_diseases.length > 0 ||
        studentObject.other_diseases.length > 0
      "
    >
      <v-col cols="12" sm="12" class="first-info">
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h3 id="info-header" class="info-header">
                      {{ $t("Medical History") }}
                    </h3>
                  </v-col>
                  <v-col cols="12" sm="12" id="info-div" class="info-div">
                    <v-row
                      v-if="studentObject.communicable_diseases.length > 0"
                    >
                      <h5 style="color: #7297ff; margin-left: 12px">
                        {{ $t("Communicable Diseases") }}
                      </h5>
                    </v-row>
                    <v-row
                      v-if="studentObject.communicable_diseases.length > 0"
                    >
                      <v-col
                        cols="12"
                        md="2"
                        v-for="(
                          com_disease, index
                        ) in studentObject.communicable_diseases"
                        :key="index"
                      >
                        {{ com_disease.name_en }} <br />
                        {{ com_disease.name_ar }}
                      </v-col>
                    </v-row>
                    <br />
                    <v-row v-if="studentObject.other_diseases.length > 0">
                      <h5
                        style="color: #7297ff; margin-left: 12px"
                        v-if="studentObject.communicable_diseases.length > 0"
                      >
                        {{ $t("Other Diseases") }}
                      </h5>
                      <h5
                        style="color: #7297ff; margin-left: 12px"
                        v-if="studentObject.communicable_diseases.length == 0"
                      >
                        {{ $t("Diseases") }}
                      </h5>
                    </v-row>
                    <v-row v-if="studentObject.other_diseases.length > 0">
                      <v-col
                        cols="12"
                        md="2"
                        v-for="(
                          other_disease, index
                        ) in studentObject.other_diseases"
                        :key="index"
                      >
                        {{ other_disease.name_en }} <br />
                        {{ other_disease.name_ar }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- endcard -->
    <!-- Papers-->
    <v-row class="my-5">
      <v-col cols="12" sm="12" class="first-info">
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h3 id="info-header" class="info-header">
                      {{ $t("Student Submitted Files") }}
                    </h3>
                  </v-col>
                  <v-col
                    cols="12"
                    xs="4"
                    md="3"
                    v-for="(item, index) in files"
                    :key="index"
                    class="diseases"
                  >
                    <v-checkbox
                      class="mx-2"
                      :style="
                        currentAppLocale == 'ar'
                          ? 'text-align:right'
                          : 'text-align:left'
                      "
                      v-model="filesSubmitted"
                      :value="item.id"
                      @click="SubmitFiles"
                    >
                      <template slot="label">
                        <label
                          class="name_ar"
                          v-html="
                            currentAppLocale == 'ar'
                              ? item.name_ar
                              : item.name_en
                          "
                        >
                        </label>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="my-5" v-if="studentDocuments.length > 0">
      <v-col cols="12" sm="12" class="first-info">
        <v-card class="mx-auto" shaped style="min-height: 500px">
          <v-toolbar flat>
            <v-toolbar-title style="color: #7297ff">{{
              $t("Student Uploaded Documents")
            }}</v-toolbar-title>
          </v-toolbar>
          <v-tabs vertical>
            <v-tab
              v-for="(item, index) in studentDocuments"
              :key="index"
              style="color: #7297ff; justify-content: start !important"
            >
              <v-icon left v-if="item.file.includes('pdf')">
                description
              </v-icon>
              <v-icon left v-else> image </v-icon>
              {{ item.document }}
            </v-tab>

            <v-tab-item v-for="(item, index) in studentDocuments" :key="index">
              <v-card flat>
                <v-card-text style="text-align: center">
                  <v-img
                    v-if="!item.file.includes('pdf')"
                    :src="item.file"
                    contain
                    height="400px"
                    @click="showImg(item.file)"
                    style="margin: 20px auto; cursor: pointer"
                  ></v-img>
                  <iframe v-else :src="item.file" width="40%" height="400px">
                  </iframe>
                  <!-- <object
                    v-else
                    :data="item.file"
                    type="application/pdf"
                    width="40%"
                    height="40%"
                    style="margin: 50px auto"
                  ></object> -->
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="my-5">
      <v-col
        cols="12"
        sm="6"
        class="first-info"
        v-if="studentObject.know_about_us != ''"
      >
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h3 id="info-header" class="info-header">
                      {{ $t("How did you know about us ?") }}
                    </h3>
                  </v-col>
                  <v-col cols="12" sm="12" id="info-div" class="info-div">
                    <p class="capital">
                      {{
                        studentObject.know_about_us != "other"
                          ? studentObject.know_about_us
                          : studentObject.know_about_us_other
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="first-info"
        v-if="studentObject.participate_voluntary != ''"
      >
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h3 id="info-header" class="info-header">
                      {{ $t("Would you like to participate ?") }}
                    </h3>
                  </v-col>
                  <v-col cols="12" sm="12" id="info-div" class="info-div">
                    {{
                      studentObject.participate_voluntary
                        ? studentObject.participate_voluntary_details
                        : ""
                    }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- payment History -->
    <v-row class="my-5">
      <v-col cols="12" md="12">
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12" md="12">
                <h3
                  class="info-header"
                  style="cursor: pointer"
                  @click="opentPaymentHistory()"
                >
                  {{ $t("Show  Student Payment History") }}
                </h3>
              </v-col>
              <v-col cols="12" md="12" v-if="showPaymentHistort">
                <div>
                  <v-data-table
                    :headers="invoicestable.headers"
                    :items="invoices"
                    class="elevation-1 table-striped"
                    :show-select="false"
                    hide-default-footer
                  >
                    <!-- invoice_number -->
                    <!-- v-if="invoicestable.permissions.dowenload" -->
                    <!-- <template v-slot:item.invoice_number="{ item }">
                    <a
                      @click="printInvoice(item.id)"
                      style="text-decoration: underline; color: #7295fb"
                      >{{ item.invoice_number }}</a
                    >
                  </template> -->
                    <!-- payment_status -->
                    <template v-slot:item.invoice_number="{ item }">
                      <router-link
                        :to="'/invoices/' + item.id"
                        target="_blank"
                        >{{ item.invoice_number }}</router-link
                      >
                    </template>
                    <template v-slot:item.payment_status="{ item }">
                      <span
                        v-if="item.payment_status == 1"
                        style="color: green"
                      >
                        {{ "Paid" }}
                      </span>
                      <span
                        v-else-if="item.payment_status == 2"
                        style="color: #fbd540"
                      >
                        {{ "PartialyPaid" }}
                      </span>
                      <span v-else style="color: red">{{ "Unpaid" }}</span>
                    </template>
                    <!-- payment_status -->
                    <!-- <template v-slot:item.action="{ item }">
                      <v-icon @click="printInvoice(item.id)">print</v-icon>
                    </template> -->
                  </v-data-table>
                  <div class="text-center pt-2">
                    <!-- <v-pagination
                      v-if="length > 0 && totalItems > 0"
                      v-model="page"
                      :length="length"
                      :circle="circle"
                      :page="page"
                      :total-visible="totalVisible"
                      next-icon="mdi-menu-right"
                      prev-icon="mdi-menu-left"
                    ></v-pagination> -->
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      student_id: "",
      applicantObject: [],
      studentObject: [],
      studentDocuments: [],
      father_saudi: false,
      showPaymentHistort: false,
      mother_saudi: false,
      files: [],
      filesSubmitted: [],
      invoices: [],
      invoicestable: {
        modal: "Parent Invoice",
        // path: "",
        headers: [
          {
            text: this.$i18n.t("Invoice Number"),
            value: "invoice_number",
            sortable: false,
          },
          // {
          //   text: this.$i18n.t("student"),
          //   value: "student",
          //   sortable: false,
          // },
          {
            text: this.$i18n.t("Amount"),
            value: "amount",
            sortable: false,
          },
          {
            text: this.$i18n.t("Amount After Discount"),
            value: "total_after_discount",
            sortable: false,
          },
          {
            text: this.$i18n.t("Status"),
            value: "payment_status",
            sortable: false,
          },
          {
            text: this.$i18n.t("Date"),
            value: "invoice_date",
            sortable: false,
          },

          // {
          //   text: this.$i18n.t("Actions"),
          //   value: "actions",
          //   sortable: false,
          //   width: "12%",
          // },
        ],
        title: "Invoices",
      },
      page: 1,
      circle: true,
      length: 0,
      totalVisible: 0,
      totalItems: 0,
    };
  },
  computed: {
    ...mapGetters(["getBackEndUrl"]),
  },
  watch: {
    page: function () {
      this.getStudentInvoices(this.student_id);
    },
  },
  methods: {
    opentPaymentHistory() {
      if (this.showPaymentHistort) {
        this.showPaymentHistort = false;
      } else {
        if (this.invoices.length == 0) {
          if (this.student_id != "") {
            this.getStudentInvoices(this.student_id);
          }
        }

        this.showPaymentHistort = true;
      }
    },
    getStudentInvoices(id) {
      axios
        .get(this.getApiUrl + "/students/getStudentInvoices/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          // console.log(response.data.data.data);
          this.invoices = [];
          this.invoices = response.data.data;
          // this.setPaginationParameters(response);
          // this.totalItems = response.data.data.total;
        });
    },
    setPaginationParameters(response) {
      // this.page = response.data.data.current_page;
      this.length = response.data.data.last_page;
      if (this.length > 1) {
        console.log("total vis");
        this.totalVisible = 5;
      }
    },
    SubmitFiles() {
      // console.log(this.filesSubmitted);
      axios
        .post(
          this.getApiUrl + "/students/getAllStudentFiles/" + this.student_id,
          { files: this.filesSubmitted },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          console.log(response);
        });
    },

    // login as User
    loginAsUser(userId) {
      axios
        .get(this.getApiUrl + "/impersonate/" + userId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            let id = response.data.data.user.id;
            let token = response.data.data.access_token;
            let school = response.data.data.school;
            let logo = response.data.data.logo;
            let school_name = response.data.data.school_name;
            localStorage.user_name = response.data.data.user.name;
            // localStorage to store user cradentials
            localStorage.id = id;
            localStorage.email = response.data.data.user.email;
            localStorage.lastLogin = response.data.data.lastLogin;
            localStorage.login = true;
            localStorage.token = token;
            localStorage.school = school;
            localStorage.logo = logo;
            localStorage.school_name = school_name;
            localStorage.expiry = new Date().getTime() + 86400000; // 24 hours
            // localStorage.expiry = new Date().getTime() + 60000; // 1 minute
            localStorage.permissionsObject = JSON.stringify(
              response.data.data.permissions
            );
            localStorage.menus = JSON.stringify(response.data.data.menus);
            localStorage.roles = JSON.stringify(response.data.data.roles);
            if (
              response.data.data.roles &&
              response.data.data.roles.length == 1
            ) {
              localStorage.selectedRole = response.data.data.roles[0];
            } else {
              localStorage.selectedRole = "";
            }
            localStorage.selectedSomeRole = false;
            localStorage.loginAs = response.data.data.login_as;
            if (localStorage.loginAs) {
              localStorage.old_user = response.data.data.old_user;
              localStorage.old_user_name = response.data.data.old_user_name;
            }
            localStorage.updateData = response.data.data.updateData;
            this.$router.push({
              path: `/`,
            });
            window.location = "/";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showImg(url) {
      window.open(url, "_blank");
    },
  },
  mounted() {
    this.student_id = this.$router.currentRoute.params.student_id;
    axios
      .get(this.getApiUrl + "/students/getAllStudentFiles", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.files = response.data.data.files;
      });
    axios
      .get(
        this.getApiUrl +
          "/students/getAllStudentSubmittedFiles/" +
          this.student_id,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      )
      .then((response) => {
        this.filesSubmitted = response.data.data.files;
      });
    axios
      .get(this.getApiUrl + "/students/show/" + this.student_id, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        if (response.data.status.error) {
          window.location = "/404";
        } else {
          this.applicantObject = response.data.data.applicantObject;
          this.studentObject = response.data.data.studentObject;
          this.studentDocuments = response.data.data.studentObject.documents;
          this.applicantObject.contact_person.communication_method =
            this.applicantObject.contact_person.communication_method.replace(
              /,/g,
              ", "
            );

          if (this.studentObject.father.nationality_id == 187) {
            this.father_saudi = true;
          }
          if (this.studentObject.mother.nationality_id == 187) {
            this.mother_saudi = true;
          }
          if (this.studentObject.know_about_us == "socialmedia") {
            this.studentObject.know_about_us = "social media";
          }
        }
      });
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

.v-application p {
  margin-bottom: 0 !important;
}
.logo-div-img {
  margin-top: 15%;
}
#wrapper.active {
  padding-left: 200px !important;
}
.icon {
  color: #7297ff !important;
  cursor: pointer;
}
.academic_history_container {
  margin-top: 2% !important;
}
.capital {
  text-transform: capitalize;
}

.first-info .v-card,
.second-info .v-card {
  // height: 100%;
}
</style>
