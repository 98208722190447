<template>
  <div>
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->

    <v-row>
      <v-col xs="6" md="6">
        <p class="main-color main-header">
          {{ $t("Student Application") }}
          <span
            class="badge"
            :class="
              student_status == 'unapproved' ? 'badge-danger' : 'badge-success'
            "
          >
            {{ $t(student_status) }}
          </span>
        </p>
      </v-col>
      <v-col xs="6" md="6" class="edit-icon">
        <!-- @click="update" -->
        <!-- <button type="submit" v-if="editMode" class="ui-btn submit">
          Save
        </button> -->
        <v-icon
          v-if="
            (!editMode && approveBtnVisible && student_status == 'new') ||
              student_status == 'unapproved'
          "
          class="mr-2 icon"
          big
          :disabled="approveDisabledBtn"
          @click="approveStudent"
          :title="$t('Approve')"
        >
          check_circle
        </v-icon>
        <v-icon
          v-if="
            !editMode && unapproveBtnVisible && student_status == 'approved'
          "
          class="mr-2 icon unapprove-icon"
          big
          :disabled="unapproveDisabledBtn"
          @click="unapproveStudent"
          :title="$t('Unapprove')"
        >
          close_circle_outline
        </v-icon>
        <v-icon
          class="mr-2 icon"
          big
          v-if="!editMode && printBtnVisible"
          @click="printPageStudent"
          :title="$t('Print')"
        >
          print
        </v-icon>
        <v-icon
          big
          class="mr-2 icon"
          v-if="!editMode && editBtnVisible"
          @click.prevent="editMode = true"
          :title="$t('Edit')"
          >edit</v-icon
        >

        <!-- Button trigger modal -->
      </v-col>
    </v-row>
    <StudentProfileShow v-if="!editMode"></StudentProfileShow>
    <StudentProfileEdit
      v-if="editMode && editBtnVisible"
      v-bind:editMode="editMode"
      v-on:submitUpdate="submitUpdateData"
      v-on:cancelEdit="cancelEdit"
    ></StudentProfileEdit>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ snackText }}
      </span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import StudentProfileShow from "../../components/Students/StudentProfileShow";
import StudentProfileEdit from "../../components/Students/StudentProfileEdit";
import axios from "axios";
import ACL from "../../acl";
export default {
  data() {
    return {
      editMode: false,
      student_id: "",
      studentObject: [],
      applicantObject: [],
      editBtnVisible: ACL.checkPermission("edit-students"),
      approveBtnVisible: ACL.checkPermission("approve-students"),
      unapproveBtnVisible: ACL.checkPermission("unapprove-students"),
      printBtnVisible: ACL.checkPermission("print-student-profile"),
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 3000,
      approveDisabledBtn: false,
      unapproveDisabledBtn: false,
      student_status: "",
    };
  },
  methods: {
    approveStudent() {
      axios
        .get(this.getApiUrl + "/students/approveStudent/" + this.student_id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error) {
            this.snack = true;
            this.snackColor = "error";
            this.snackText = response.data.status.message;
          } else {
            this.snack = true;
            this.snackColor = "green";
            this.snackText = response.data.status.message;
            this.student_status = "approved";
            this.unapproveDisabledBtn = false;
            this.approveDisabledBtn = true;
          }
        });
    },
    unapproveStudent() {
      axios
        .get(this.getApiUrl + "/students/unapproveStudent/" + this.student_id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error) {
            this.snack = true;
            this.snackColor = "error";
            this.snackText = response.data.status.message;
          } else {
            this.snack = true;
            this.snackColor = "green";
            this.snackText = response.data.status.message;
            this.student_status = "unapproved";
            this.approveDisabledBtn = false;
            this.unapproveDisabledBtn = true;
          }
        });
    },
    submitUpdateData(
      applicantObject,
      studentObject,
      editMode,
      snack = null,
      snackColor = null,
      snackText = null
    ) {
      this.editMode = editMode;
      this.applicantObject = applicantObject;
      this.studentObject = studentObject;
      this.snack = snack;
      this.snackColor = snackColor;
      this.snackText = snackText;
    },
    // submitUpdateData(applicantObject, studentObject) {
    //   this.applicantObject = applicantObject;
    //   this.studentObject = studentObject;

    //   axios
    //     .post(
    //       this.getApiUrl + "/students/update/" + this.student_id,
    //       {
    //         applicantData: this.applicantObject,
    //         studentData: this.studentObject
    //       },
    //       {
    //         headers: {
    //           Authorization: "Bearer " + localStorage.token
    //         }
    //       }
    //     )
    //     .then(response => {
    //       if (response.data.status.error == false) this.editMode = false;
    //       this.snack = true;
    //       this.snackColor = "green";
    //       this.snackText = response.data.status.message;
    //     });
    // },
    cancelEdit() {
      this.editMode = false;
    },
    printPageStudent() {
      axios
        .get(this.getApiUrl + "/students/printPdfStudent/" + this.student_id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          var link = response.data.data;
          window.open(link, "_blank");
        });
    },
  },
  mounted() {
    console.log(this.student_status);
    if (this.$route.query.editable && this.editBtnVisible) {
      this.editMode = this.$route.query.editable;
      this.student_id = this.$router.currentRoute.params.student_id;
    } else {
      this.student_id = this.$router.currentRoute.params.student_id;
    }

    axios
      .get(this.getApiUrl + "/students/studentStatus/" + this.student_id, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.student_status = response.data.data;
        if (this.student_status == "approved") {
          this.approveDisabledBtn = true;
        }
        if (this.student_status == "new") {
          this.unapproveDisabledBtn = true;
        }
      });
  },
  components: {
    StudentProfileShow,
    StudentProfileEdit,
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

.v-application p {
  margin-bottom: 0 !important;
}
.logo-div-img {
  margin-top: 15%;
}
#wrapper.active {
  padding-left: 200px !important;
}
.icon {
  color: #7297ff !important;
  cursor: pointer;
}
.unapprove-icon {
  position: absolute;
  margin-left: 150px;
  width: 10px;
}
</style>
