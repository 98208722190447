<template>
  <v-form
    class="profile-section2 mt-5"
    v-model="valid"
    ref="form"
    id="studentProfile"
    v-if="studentObject && applicantObject"
  >
    <!-- start card -->
    <v-row>
      <v-col cols="12" sm="12" class="first-info">
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h3 id="info-header" class="info-header">
                      {{ $t("General Information") }}
                    </h3>
                  </v-col>
                  <v-col cols="12" sm="4" class="text-center" id="logo-div">
                    <div class="logo-div-img">
                      <img
                        v-if="
                          studentObject.image != null || studentObject != ''
                        "
                        :src="this.getBackendUrl + studentObject.image"
                        class="mg-t-30"
                      />
                      <img
                        v-if="
                          studentObject.image == null || studentObject == ''
                        "
                        class="mg-t-30"
                        src="../../assets/avatar.png"
                      />
                    </div>
                    <div class="custom-file logo-div-img" id="upload-photo">
                      <input
                        type="file"
                        class="custom-file-input"
                        @change="onFileSelected"
                      />
                      <label class="custom-file-label" for="customFile">
                        <br
                      /></label>
                    </div>
                    <img
                      src="../../assets/img/preloader.gif"
                      id="preloader-custom"
                      alt="preloader"
                      class="hidden"
                    />
                    <div v-if="true">
                      <p class="error mg-t-30">
                        {{ logoError }}
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="8" id="info-div" class="info-div">
                    <div class="editData">
                      <v-row>
                        <v-col cols="12" xs="12" md="4">
                          <p>
                            <strong>{{ $t("Student Code") }}:</strong>
                          </p>
                        </v-col>

                        <v-col cols="12" xs="12" class="" md="8">
                          <v-text-field
                            @focus="onFocus()"
                            solo
                            v-model="applicantObject.application_number"
                            :disabled="true"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" xs="12" md="4">
                          <p>
                            <strong>{{ $t("First Name") }}:</strong>
                          </p>
                        </v-col>
                        <v-col cols="12" xs="12" class="" md="8">
                          <v-text-field
                            @focus="onFocus()"
                            solo
                            v-model="applicantObject.firstname"
                            :rules="validationRulesApplicant.firstname"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" xs="12" md="4">
                          <p>
                            <strong>{{ $t("Father's Name") }}:</strong>
                          </p>
                        </v-col>
                        <v-col cols="12" xs="12" class="" md="8">
                          <v-text-field
                            @focus="onFocus()"
                            solo
                            v-model="applicantObject.fathername"
                            :rules="[validation.maxLength50]"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" xs="12" md="4">
                          <p>
                            <strong>{{ $t("GrandFather Name") }}:</strong>
                          </p>
                        </v-col>
                        <v-col cols="12" xs="12" class="" md="8">
                          <v-text-field
                            @focus="onFocus()"
                            solo
                            v-model="applicantObject.student_grand_father_name"
                            :rules="[validation.maxLength50]"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" xs="12" md="4">
                          <p>
                            <strong>{{ $t("Family Name") }}:</strong>
                          </p>
                        </v-col>
                        <v-col cols="12" xs="12" class="" md="8">
                          <v-text-field
                            @focus="onFocus()"
                            solo
                            v-model="applicantObject.familyname"
                            :rules="validationRulesApplicant.familyname"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <!--    <v-row>
                        <v-col xs="4" md="4">
                          <p><strong>الاسم الاول:</strong></p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <v-text-field
                            @focus="onFocus()"
                            solo
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col xs="4" md="4">
                          <p><strong>اسم الأب:</strong></p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <v-text-field
                            @focus="onFocus()"
                            solo
                            dense
                          ></v-text-field>

                       
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col xs="4" md="4">
                          <p><strong>العائلة:</strong></p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <v-text-field
                            @focus="onFocus()"
                            solo
                            dense
                          ></v-text-field>

                        </v-col>
                      </v-row> -->

                      <v-row>
                        <v-col cols="12" xs="12" md="4">
                          <p>
                            <strong>{{ $t("Birthdate Gregorian") }}:</strong>
                          </p>
                        </v-col>
                        <v-col cols="12" xs="12" class="" md="8">
                          <v-text-field
                            id="gregorianbirth"
                            autocomplete="off"
                            class="dateIcon"
                            @focus="onFocus()"
                            solo
                            v-model="applicantObject.date_of_birth"
                            :rules="validationRulesApplicant.date_of_birth"
                            append-icon="event"
                            @keydown.prevent
                            @paste.prevent
                            @drop.prevent
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" xs="12" md="4">
                          <p>
                            <strong>{{ $t("Birthdate Hijri") }}:</strong>
                          </p>
                        </v-col>
                        <v-col cols="12" xs="12" class="" md="8">
                          <v-text-field
                            @focus="onFocus()"
                            id="hijri"
                            autocomplete="off"
                            solo
                            v-model="applicantObject.date_of_birth_hijri"
                            :rules="
                              validationRulesApplicant.date_of_birth_hijri
                            "
                            append-icon="event"
                            class="dateIcon"
                            @keydown.prevent
                            @paste.prevent
                            @drop.prevent
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" xs="12" md="4">
                          <p>
                            <strong>{{ $t("Place Of Birth") }}:</strong>
                          </p>
                        </v-col>
                        <v-col cols="12" xs="12" class="" md="8">
                          <v-text-field
                            @focus="onFocus()"
                            autocomplete="off"
                            solo
                            v-model="applicantObject.place_of_birth"
                            :rules="validationRulesApplicant.place_of_birth"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" xs="12" md="4">
                          <p>
                            <strong>{{ $t("Gender") }}:</strong>
                          </p>
                        </v-col>
                        <v-col cols="12" xs="12" class="" md="8">
                          <div class="radio">
                            <div class="flex-center">
                              <v-radio-group
                                row
                                v-model="applicantObject.gender"
                              >
                                <v-radio
                                  :label="$t('Male')"
                                  value="male"
                                ></v-radio>
                                <v-radio
                                  :label="$t('Female')"
                                  value="female"
                                ></v-radio>
                              </v-radio-group>
                            </div>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" xs="12" md="4">
                          <p>
                            <strong>{{ $t("Nationality") }}:</strong>
                          </p>
                        </v-col>
                        <v-col cols="12" xs="12" class="" md="8">
                          <div class="select">
                            <v-autocomplete
                              :items="dataObject.nationalities"
                              item-text="name"
                              item-value="id"
                              @change="onFocus()"
                              solo
                              v-model="applicantObject.nationality"
                              :select="onChangeNationality()"
                            >
                            </v-autocomplete>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row v-if="applicantObject.nationality == 187">
                        <v-col cols="12" xs="12" md="4">
                          <p>
                            <strong>{{ $t("National ID") }}:</strong>
                          </p>
                        </v-col>
                        <v-col cols="12" xs="12" md="8">
                          <v-text-field
                            @focus="onFocus()"
                            autocomplete="off"
                            solo
                            v-model="applicantObject.national_id"
                            :rules="[
                              validation.required,
                              validation.numeric,
                              checkNationalIdUnique(
                                applicantObject.national_id,
                                studentObject.father.national_id,
                                studentObject.mother.national_id
                              ),
                            ]"
                          ></v-text-field>

                          <div
                            v-if="
                              validation_errors.applicantData &&
                              validation_errors.applicantData.national_id
                            "
                            style="margin: 2%"
                          >
                            <p
                              class="red--text"
                              v-for="(error, index) in validation_errors
                                .applicantData.national_id"
                              :key="index"
                            >
                              {{ error }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row v-if="applicantObject.nationality != 187">
                        <v-col cols="12" xs="12" md="4">
                          <p>
                            <strong>{{ $t("Passport Number") }}:</strong>
                          </p>
                        </v-col>
                        <v-col cols="12" xs="12" md="8" class="">
                          <v-text-field
                            @focus="onFocus()"
                            autocomplete="off"
                            solo
                            v-model="applicantObject.passport_no"
                            :rules="[validation.required]"
                          ></v-text-field>
                          <div
                            v-if="
                              validation_errors.applicantData &&
                              validation_errors.applicantData.passport_no
                            "
                            style="margin: 2%"
                          >
                            <p
                              class="red--text"
                              v-for="(error, index) in validation_errors
                                .applicantData.passport_no"
                              :key="index"
                            >
                              {{ error }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row v-if="applicantObject.nationality != 187">
                        <v-col cols="12" xs="12" md="4">
                          <p>
                            <strong>{{ $t("Iqama Number") }}:</strong>
                          </p>
                        </v-col>
                        <v-col cols="12" xs="12" md="8" class="">
                          <v-text-field
                            @focus="onFocus()"
                            autocomplete="off"
                            solo
                            v-model="applicantObject.iqama_no"
                            :rules="[validation.required, validation.alphaNum]"
                          ></v-text-field>
                          <div
                            v-if="
                              validation_errors.applicantData &&
                              validation_errors.applicantData.iqama_no
                            "
                          >
                            <p
                              class="red--text"
                              v-for="(error, index) in validation_errors
                                .applicantData.iqama_no"
                              :key="index"
                            >
                              {{ error }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row v-if="applicantObject.nationality != 187">
                        <v-col cols="12" xs="12" md="4">
                          <p>
                            <strong>{{ $t("Iqama Expiry Date") }}:</strong>
                          </p>
                        </v-col>
                        <v-col cols="12" xs="12" md="8" class="">
                          <v-text-field
                            @focus="onFocus()"
                            autocomplete="off"
                            solo
                            id="iqamaDate"
                            v-model="applicantObject.iqama_expiry_date"
                            append-icon="event"
                            class="dateIcon"
                            :rules="[validation.required]"
                            @keydown.prevent
                            @paste.prevent
                            @drop.prevent
                          ></v-text-field>
                          <div
                            v-if="
                              validation_errors.applicantData &&
                              validation_errors.applicantData.iqama_expiry_date
                            "
                          >
                            <p
                              class="red--text"
                              v-for="(error, index) in validation_errors
                                .applicantData.iqama_expiry_date"
                              :key="index"
                            >
                              {{ error }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" xs="12" md="4">
                          <p>
                            <strong>{{ $t("Religion") }}:</strong>
                          </p>
                        </v-col>
                        <v-col cols="12" xs="12" class="" md="8">
                          <div class="select">
                            <!-- <v-select
                              :items="religions"
                              item-text="key"
                              item-value="value"
                              @focus="onFocus()"
                              solo
                              v-model="applicantObject.religion"
                            ></v-select> -->
                            <v-radio-group
                              row
                              v-model="applicantObject.religion"
                            >
                              <v-radio :label="$t('Muslim')" value="muslim">
                              </v-radio>
                              <v-radio
                                :label="$t('Non-muslim')"
                                value="non-muslim"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- endcard -->
    <v-row class="py-10">
      <v-col cols="12" sm="6" class="first-info technical-details">
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h3 id="info-header" class="info-header">
                      {{ $t("Technical Details") }}
                    </h3>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    id="info-div"
                    class="info-div"
                    style="padding-left: 10px; padding-right: 10px"
                  >
                    <v-row>
                      <v-col cols="12" xs="12" md="4">
                        <p>
                          <strong> {{ $t("Date of Enrollment") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="8" class="">
                        <v-text-field
                          @focus="onFocus()"
                          autocomplete="off"
                          solo
                          disabled
                          v-model="studentObject.date_of_enrollment"
                          append-icon="event"
                          class="dateIcon"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="4">
                        <p>
                          <strong> {{ $t("Previous School") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="8" class="">
                        <v-text-field
                          @focus="onFocus()"
                          autocomplete="off"
                          solo
                          v-model="applicantObject.previous_school"
                          :rules="validationRulesApplicant.previous_school"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="4">
                        <p>
                          <strong>{{ $t("Native Language") }} :</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="8" class="">
                        <v-select
                          :items="languages"
                          item-text="key"
                          item-value="value"
                          id="native-language"
                          v-model="applicantObject.native_language"
                          solo
                          :rules="validationRulesApplicant.native_language"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="4">
                        <p>
                          <strong> {{ $t("Second Language") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="8" class="">
                        <v-select
                          :items="languages"
                          item-text="key"
                          item-value="value"
                          id="native-language"
                          v-model="applicantObject.second_language"
                          solo
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="4">
                        <p>
                          <strong>{{ $t("Grade Level") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="8" class="">
                        <v-select
                          v-if="studentObject.status_student != 'approved'"
                          :items="dataObject.grades"
                          item-text="grade_name"
                          item-value="id"
                          id="grade-level"
                          v-model.trim="studentObject.grade_level"
                          solo
                          :rules="validationRulesApplicant.grade_level"
                        ></v-select>
                        <p
                          class="value"
                          v-if="studentObject.status_student == 'approved'"
                        >
                          {{ studentObject.grade_level }}
                        </p>
                        <!-- <p
                          class="error--text"
                          v-if="gradeError"
                          :class="gradeError ? '' : 'hidden'"
                        >
                          Admission is not open for the selected grade
                        </p> -->
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="4">
                        <p>
                          <strong>{{ $t("Curriculum") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="8" class="">
                        <v-text-field
                          @focus="onFocus()"
                          autocomplete="off"
                          solo
                          v-model="applicantObject.curriculm"
                          :rules="validationRulesApplicant.curriculm"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" class="first-info">
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h3 id="info-header" class="info-header">
                      {{ $t("Contact Person Details") }}
                    </h3>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    id="info-div"
                    class="info-div"
                    style="padding-left: 10px; padding-right: 10px"
                  >
                    <v-row>
                      <v-col cols="12" xs="12" md="4">
                        <p>
                          <strong> {{ $t("Name") }}:</strong>
                        </p>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="8"
                        class=""
                        v-if="applicantObject.contact_person"
                      >
                        <v-text-field
                          @focus="onFocus()"
                          autocomplete="off"
                          solo
                          v-model="applicantObject.contact_person.name"
                          :rules="validationRulesApplicant.contact_person_name"
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.applicantData &&
                            validation_errors.applicantData.contact_person &&
                            applicantObject.contact_person.name == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .applicantData.contact_person"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="4">
                        <p>
                          <strong>{{ $t("Mobile") }} :</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="6" md="3" class="no-paddingL">
                        <div class="code" id="country-code">
                          <v-autocomplete
                            :items="dataObject.Codes"
                            class="form-control"
                            @change="hideLabel = true"
                            outlined
                            item-text="title"
                            item-value="value"
                            value="187"
                            v-model="applicantObject.country_code"
                            ><template slot="selection" slot-scope="data">
                              <v-flex xs2>
                                <v-avatar size="25px">
                                  <img :src="data.item.icon" />
                                </v-avatar>
                              </v-flex>
                              <v-flex class="ml-4">
                                {{ data.item.title }}
                              </v-flex>
                            </template>
                            <template slot="item" slot-scope="data">
                              <v-list-item-avatar
                                style="
                                  width: 20px;
                                  min-width: 20px;
                                  height: 20px;
                                "
                              >
                                <img :src="data.item.icon" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="data.item.title"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </div>
                      </v-col>
                      <v-col cols="12" xs="6" md="5" class="">
                        <v-text-field
                          @focus="onFocus()"
                          autocomplete="off"
                          solo
                          v-model="applicantObject.contact_person.mobile"
                          :rules="[
                            mobileRules.required,
                            mobileRules.foramt,
                            mobileRule(
                              applicantObject.country_code,
                              applicantObject.contact_person.mobile
                            ),
                          ]"
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.applicantData &&
                            validation_errors.applicantData.contact_person &&
                            applicantObject.contact_person.mobile == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .applicantData.contact_person"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="4">
                        <p>
                          <strong> {{ $t("Email") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="8" class="">
                        <v-text-field
                          @focus="onFocus()"
                          autocomplete="off"
                          solo
                          v-model="applicantObject.contact_person.email"
                          :rules="validationRulesApplicant.contact_person_email"
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.applicantData &&
                            validation_errors.applicantData.contact_person &&
                            applicantObject.contact_person.email == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .applicantData.contact_person"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="4">
                        <p>
                          <strong>{{ $t("Relationship") }} :</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="8">
                        <div class="radio">
                          <div
                            class="flex-center"
                            style="margin-top: -15px !important"
                          >
                            <v-radio-group
                              v-model="
                                applicantObject.contact_person.relation_ship
                              "
                              row
                              @change="onOther()"
                              :rules="
                                validationRulesApplicant.contact_person_relation_ship
                              "
                            >
                              <v-radio
                                :label="$i18n.t('Father')"
                                value="father"
                                id="father"
                              ></v-radio>
                              <v-radio
                                :label="$i18n.t('Mother')"
                                value="mother"
                                id="mother"
                              ></v-radio>
                              <!-- <v-radio
                                label="Uncle"
                                value="uncle"
                                id="uncle"
                              ></v-radio>
                              <v-radio
                                :label="$i18n.t('Other')"
                                value="other"
                                id="other"
                              ></v-radio> -->
                            </v-radio-group>
                            <div
                              v-if="
                                validation_errors.applicantData &&
                                validation_errors.applicantData
                                  .contact_person &&
                                applicantObject.contact_person.relation_ship ==
                                  ''
                              "
                            >
                              <p
                                class="red--text"
                                v-for="(error, index) in validation_errors
                                  .applicantData.contact_person"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row :hidden="other">
                      <v-col cols="12" xs="12" md="4">
                        <p><strong></strong></p>
                      </v-col>
                      <v-col
                        xs="8"
                        class=""
                        md="8"
                        style="margin-top: -35px !important"
                      >
                        <v-text-field
                          @focus="onFocus()"
                          v-model.trim="
                            applicantObject.contact_person.relation_ship_other
                          "
                          solo
                          :rules="otherContactRule"
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.applicantData &&
                            validation_errors.applicantData.contact_person &&
                            applicantObject.contact_person.relation_ship ==
                              'other' &&
                            applicantObject.contact_person
                              .relation_ship_other == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .applicantData.contact_person"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="4">
                        <p>
                          <strong> {{ $t("Communication Method") }}:</strong>
                        </p>
                      </v-col>

                      <v-col cols="12" xs="12" class="" md="8">
                        <v-row
                          style="
                            margin-left: -10px !important;
                            margin-top: -10px !important;
                          "
                        >
                          <v-checkbox
                            class="mx-2"
                            :label="$t('SMS')"
                            id="sms"
                            value="sms"
                            v-model="
                              applicantObject.contact_person
                                .communication_method
                            "
                            :rules="
                              validationRulesApplicant.contact_person_communication_method
                            "
                          ></v-checkbox>

                          <v-checkbox
                            class="mx-2"
                            :label="$t('E-mail')"
                            id="email"
                            value="email"
                            v-model="
                              applicantObject.contact_person
                                .communication_method
                            "
                          ></v-checkbox>
                          <div
                            v-if="
                              validation_errors.applicantData &&
                              validation_errors.applicantData.contact_person &&
                              applicantObject.contact_person
                                .communication_method == ''
                            "
                          >
                            <p
                              class="red--text"
                              v-for="(
                                error, index
                              ) in validation_errors.contact_person"
                              :key="index"
                            >
                              {{ error }}
                            </p>
                          </div>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- endcard -->
    <v-row>
      <v-col cols="12" sm="6" class="first-info">
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h3 id="info-header" class="info-header">
                      <p class="loginAsIcon mb-0">
                        {{ $t("Mother Details") }}
                        <v-btn
                          v-if="studentObject.mother.user_id"
                          :title="$t('Login as mother')"
                          @click="loginAsUser(studentObject.mother.user_id)"
                          icon
                        >
                          <v-icon>mdi-login</v-icon>
                        </v-btn>
                      </p>
                    </h3>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    id="info-div"
                    class="info-div"
                    style="padding-left: 10px; padding-right: 10px"
                  >
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong> {{ $t("First Name") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="7" class="">
                        <!-- <p class="value">
                          {{ studentObject.mother.firstname }}
                        </p> -->
                        <v-text-field
                          v-model="studentObject.mother.firstname"
                          :rules="validationRules[3].firstname"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.mother &&
                            studentObject.mother.firstname == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.mother"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong> {{ $t("Middle Name") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="7" class="">
                        <v-text-field
                          v-model="studentObject.mother.middlename"
                          :rules="validationRules[3].middlename"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.mother &&
                            studentObject.mother.middlename == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.mother"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong> {{ $t("Family Name") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="7" class="">
                        <v-text-field
                          v-model="studentObject.mother.familyname"
                          :rules="validationRules[3].familyname"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.mother &&
                            studentObject.mother.familyname == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.mother"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="5">
                        <p>
                          <strong>{{ $t("Nationality") }}</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" md="7">
                        <v-autocomplete
                          :items="dataObject.nationalities"
                          item-text="name"
                          item-value="id"
                          v-model.trim="studentObject.mother.nationality_id"
                          :rules="[validation.required]"
                          :select="motherNationalityOnSelect()"
                          solo
                        >
                        </v-autocomplete>
                        <!-- :select="onSelect()" -->
                      </v-col>
                    </v-row>

                    <v-row v-if="mother_saudi">
                      <v-col cols="12" md="5">
                        <p>
                          <strong>{{ $t("National ID") }}</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" md="7">
                        <v-text-field
                          id="national-id"
                          v-model.trim="studentObject.mother.national_id"
                          :rules="[
                            validation.required,
                            validation.numeric,
                            checkNationalIdUniqueMother(
                              applicantObject.national_id,
                              studentObject.father.national_id,
                              studentObject.mother.national_id
                            ),
                          ]"
                          @focus="onFocus()"
                          solo
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row
                      class="form-item"
                      v-if="
                        !mother_saudi &&
                        studentObject.mother.nationality_id != ''
                      "
                    >
                      <v-col cols="12" md="5">
                        <p>
                          <strong>{{ $t("Iqama No") }}</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" md="7">
                        <v-text-field
                          id="iqama-number"
                          v-model.trim="studentObject.mother.parent_iqama_no"
                          :rules="[validation.required, validation.alphaNum]"
                          @focus="onFocus()"
                          solo
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row
                      class="form-item"
                      v-if="
                        !mother_saudi &&
                        studentObject.mother.nationality_id != ''
                      "
                    >
                      <v-col cols="12" md="5">
                        <p>
                          <strong>{{ $t("Expiry Date") }}</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" md="7">
                        <v-text-field
                          id="motherIqamaexpiry"
                          append-icon="event"
                          @focus="onFocus()"
                          autocomplete="off"
                          v-model.trim="
                            studentObject.mother.parent_iqama_no_expire_date
                          "
                          :rules="[validation.required]"
                          solo
                          dense
                          @keydown.prevent
                          @paste.prevent
                          @drop.prevent
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong> {{ $t("Education Level") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="7" class="">
                        <v-text-field
                          v-model="studentObject.mother.education_level"
                          :rules="validationRules[3].education_level"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.mother &&
                            studentObject.mother.education_level == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.mother"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong>{{ $t("Occupation") }} :</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="7" class="">
                        <v-text-field
                          v-model="studentObject.mother.occupation"
                          :rules="validationRules[3].occupation"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.mother &&
                            studentObject.mother.occupation == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.mother"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong>{{ $t("Business Address") }} :</strong>
                        </p>
                      </v-col>
                      <v-col xs="7" md="7" class="">
                        <v-text-field
                          v-model="studentObject.mother.business_address"
                          :rules="validationRules[3].business_address"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.mother &&
                            studentObject.mother.business_address == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.mother"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong>{{ $t("Home Tel. Number") }} :</strong>
                        </p>
                      </v-col>
                      <v-col xs="7" md="7" class="">
                        <v-text-field
                          v-model="studentObject.mother.tel"
                          :rules="validationRules[3].tel"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.mother &&
                            studentObject.mother.tel == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.mother"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong> {{ $t("Mobile Number") }}:</strong>
                        </p>
                      </v-col>

                      <v-col cols="12" xs="12" md="3" class="noPaddingRight">
                        <div class="code" id="country-code">
                          <v-autocomplete
                            :items="dataObject.Codes"
                            class="form-control"
                            required
                            @change="hideLabel = true"
                            outlined
                            item-text="title"
                            item-value="value"
                            value="187"
                            v-model="studentObject.mother.country_id"
                            ><template slot="selection" slot-scope="data">
                              <!-- <img src="../../assets/flags/AF.png" />
                                    {{ data.title }} -->

                              <v-flex xs2>
                                <v-avatar size="25px">
                                  <img :src="data.item.icon" />
                                </v-avatar>
                              </v-flex>
                              <v-flex class="ml-4">
                                {{ data.item.title }}
                              </v-flex>
                            </template>
                            <template slot="item" slot-scope="data">
                              <v-list-item-avatar
                                style="
                                  width: 20px;
                                  min-width: 20px;
                                  height: 20px;
                                "
                              >
                                <img :src="data.item.icon" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="data.item.title"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </div>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.mother &&
                            studentObject.mother.country_id == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.mother"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>

                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          v-model="studentObject.mother.mobile"
                          :rules="[
                            mobileRules.required,
                            mobileRules.foramt,
                            mobileRule(
                              studentObject.mother.country_id,
                              studentObject.mother.mobile
                            ),
                            checkThatValuesNotSame(
                              studentObject.father.mobile,
                              studentObject.mother.mobile,
                              'mobile'
                            ),
                          ]"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.mother &&
                            studentObject.mother.mobile == ''
                          "
                        >
                          <p
                            class="red--text"
                            style="margin-top: -11%"
                            v-for="(error, index) in validation_errors
                              .studentData.mother"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong>{{ $t("Email") }} :</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="7">
                        <v-text-field
                          v-model="studentObject.mother.email"
                          :rules="[
                            validation.email,
                            validation.required,

                            checkThatValuesNotSame(
                              studentObject.father.email,
                              studentObject.mother.email,
                              'email'
                            ),
                          ]"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.mother &&
                            studentObject.mother.email == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.mother"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="6" class="first-info">
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h3 id="info-header" class="info-header">
                      <p class="loginAsIcon mb-0">
                        {{ $t("Father Details") }}
                        <v-btn
                          v-if="studentObject.father.user_id"
                          :title="$t('Login as father')"
                          @click="loginAsUser(studentObject.father.user_id)"
                          icon
                        >
                          <v-icon>mdi-login</v-icon>
                        </v-btn>
                      </p>
                    </h3>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    id="info-div"
                    class="info-div"
                    style="padding-left: 10px; padding-right: 10px"
                  >
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong> {{ $t("First Name") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="7" md="7" class="">
                        <v-text-field
                          v-model="studentObject.father.firstname"
                          :rules="validationRules[2].firstname"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.father &&
                            studentObject.father.firstname == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.father"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong> {{ $t("Middle Name") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="7" class="">
                        <v-text-field
                          v-model="studentObject.father.middlename"
                          :rules="validationRules[2].middlename"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.father &&
                            studentObject.father.middlename == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.father"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong>{{ $t("Family Name") }} :</strong>
                        </p>
                      </v-col>
                      <v-col xs="7" md="7" class="">
                        <v-text-field
                          v-model="studentObject.father.familyname"
                          :rules="validationRules[2].familyname"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.father &&
                            studentObject.father.familyname == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.father"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="5">
                        <p>
                          <strong>{{ $t("Nationality") }}</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" md="7">
                        <v-autocomplete
                          :items="dataObject.nationalities"
                          item-text="name"
                          item-value="id"
                          v-model.trim="studentObject.father.nationality_id"
                          :rules="[validation.required]"
                          @change="fatherNationalityOnSelect()"
                          solo
                        >
                        </v-autocomplete>
                        <!-- :select="onSelect()" -->
                      </v-col>
                    </v-row>

                    <v-row v-if="father_saudi">
                      <v-col cols="12" md="5">
                        <p>
                          <strong>{{ $t("National ID") }}</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" md="7">
                        <v-text-field
                          id="national-id"
                          v-model.trim="studentObject.father.national_id"
                          :rules="[
                            validation.required,
                            validation.numeric,
                            checkNationalIdUniqueFather(
                              applicantObject.national_id,
                              studentObject.father.national_id,
                              studentObject.mother.national_id
                            ),
                          ]"
                          @focus="onFocus()"
                          solo
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row
                      class="form-item"
                      v-if="
                        !father_saudi &&
                        studentObject.father.nationality_id != ''
                      "
                    >
                      <v-col cols="12" md="5">
                        <p>
                          <strong>{{ $t("Iqama No") }}</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" md="7">
                        <v-text-field
                          id="iqama-number"
                          v-model.trim="studentObject.father.parent_iqama_no"
                          :rules="[validation.required, validation.alphaNum]"
                          @focus="onFocus()"
                          solo
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row
                      class="form-item"
                      v-if="
                        !father_saudi &&
                        studentObject.father.nationality_id != ''
                      "
                    >
                      <v-col cols="12" md="5">
                        <p>
                          <strong>{{ $t("Expiry Date") }}</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" md="7">
                        <v-text-field
                          id="expiry"
                          append-icon="event"
                          @focus="onFocus()"
                          autocomplete="off"
                          v-model.trim="
                            studentObject.father.parent_iqama_no_expire_date
                          "
                          :rules="[validation.required]"
                          solo
                          dense
                          @keydown.prevent
                          @paste.prevent
                          @drop.prevent
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong> {{ $t("Education Level") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="7" class="">
                        <v-text-field
                          v-model="studentObject.father.education_level"
                          :rules="validationRules[2].education_level"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.father &&
                            studentObject.father.education_level == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.father"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong> {{ $t("Occupation") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="7" md="7" class="">
                        <v-text-field
                          v-model="studentObject.father.occupation"
                          :rules="validationRules[2].occupation"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.father &&
                            studentObject.father.occupation == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.father"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong>{{ $t("Business Address") }} :</strong>
                        </p>
                      </v-col>
                      <v-col xs="7" md="7" class="">
                        <v-text-field
                          v-model="studentObject.father.business_address"
                          :rules="validationRules[2].business_address"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.father &&
                            studentObject.father.business_address == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.father"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong> {{ $t("Home Tel. Number") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="7" class="">
                        <v-text-field
                          v-model="studentObject.father.tel"
                          :rules="validationRules[2].tel"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.father &&
                            studentObject.father.tel == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.father"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong>{{ $t("Mobile Number") }} :</strong>
                        </p>
                      </v-col>

                      <v-col cols="12" xs="12" md="3" class="noPaddingRight">
                        <div class="code" id="country-code">
                          <v-autocomplete
                            :items="dataObject.Codes"
                            class="form-control"
                            required
                            @change="hideLabel = true"
                            outlined
                            item-text="title"
                            item-value="value"
                            value="187"
                            v-model="studentObject.father.country_id"
                            ><template slot="selection" slot-scope="data">
                              <!-- <img src="../../assets/flags/AF.png" />
                                    {{ data.title }} -->

                              <v-flex xs2>
                                <v-avatar size="25px">
                                  <img :src="data.item.icon" />
                                </v-avatar>
                              </v-flex>
                              <v-flex class="ml-4">
                                {{ data.item.title }}
                              </v-flex>
                            </template>
                            <template slot="item" slot-scope="data">
                              <v-list-item-avatar
                                style="
                                  width: 20px;
                                  min-width: 20px;
                                  height: 20px;
                                "
                              >
                                <img :src="data.item.icon" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="data.item.title"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </div>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.father &&
                            studentObject.father.country_id == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.father"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>

                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          v-model="studentObject.father.mobile"
                          :rules="[
                            mobileRules.required,
                            mobileRules.foramt,
                            mobileRule(
                              studentObject.father.country_id,
                              studentObject.father.mobile
                            ),
                            checkThatValuesNotSame(
                              studentObject.father.mobile,
                              studentObject.mother.mobile,
                              'mobile'
                            ),
                          ]"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.father &&
                            studentObject.father.mobile == ''
                          "
                        >
                          <p
                            class="red--text"
                            style="margin-top: -11%"
                            v-for="(error, index) in validation_errors
                              .studentData.father"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong> {{ $t("Email") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="7" class="">
                        <v-text-field
                          v-model="studentObject.father.email"
                          :rules="[
                            validation.email,
                            validation.required,

                            checkThatValuesNotSame(
                              studentObject.father.email,
                              studentObject.mother.email,
                              'email'
                            ),
                          ]"
                          required
                          @focus="onFocus()"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.father &&
                            studentObject.father.email == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.father"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- endcard -->
    <v-row>
      <v-col cols="12" xs="12" md="2"
        ><p style="margin-top: 8px">
          {{ $t("Parents Relationship") }}
        </p></v-col
      >
      <v-col cols="12" xs="12" md="4">
        <v-radio-group
          v-model="studentObject.parents_relationship"
          row
          @change="OnotherPrentRelationship()"
          :rules="validationRules[3].parents_relationship"
        >
          <v-radio value="married" :label="$i18n.t('Married')"> </v-radio>
          <v-radio value="divorced" :label="$i18n.t('Divorced')"> </v-radio>
          <v-radio value="other" :label="$i18n.t('Other')"> </v-radio>
        </v-radio-group>
        <div
          v-if="
            validation_errors.studentData &&
            validation_errors.studentData.parents_relationship
          "
        >
          <p
            class="red--text"
            v-for="(error, index) in validation_errors.studentData
              .parents_relationship"
            :key="index"
          >
            {{ error }}
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row
      :hidden="otherParentRelationShip"
      style="margin-top: -35px !important"
    >
      <v-col cols="12" xs="12" class="" md="4" offset-md="2">
        <v-text-field
          @focus="onFocus()"
          v-model.trim="studentObject.parents_relationship_other"
          solo
          :rules="otherParentRule"
        ></v-text-field>
        <div
          v-if="
            validation_errors.studentData &&
            validation_errors.studentData.parents_relationship_other
          "
        >
          <p
            class="red--text otherParent"
            v-for="(error, index) in validation_errors.studentData
              .parents_relationship_other"
            :key="index"
          >
            {{ error }}
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="6"
        class="first-info"
        v-for="(sibling, index) in studentObject.siblings"
        :key="index"
      >
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h3 id="info-header" class="info-header float-left">
                      {{ $t("Sibling") }} {{ parseInt(index + 1) }}
                    </h3>
                    <v-icon
                      large
                      title="Delete Sibling"
                      class="mr-2 main-color deleteBtn"
                      @click="deleteSibling(index)"
                      >delete</v-icon
                    >
                  </v-col>
                  <span class="clear-fix"></span>

                  <v-col
                    cols="12"
                    sm="12"
                    id="info-div"
                    class="info-div"
                    style="padding-left: 10px; padding-right: 10px"
                  >
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong> {{ $t("Name") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="7" md="7" class="">
                        <v-text-field
                          v-model="sibling.name"
                          :rules="validationRules[4].name"
                          autocomplete="off"
                          solo
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.siblings &&
                            sibling.name == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.siblings"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong> {{ $t("Gender") }}:</strong>
                        </p>
                      </v-col>
                      <v-col xs="7" md="7" class="">
                        <v-radio-group
                          v-model="sibling.gender"
                          :rules="validationRules[4].gender"
                          row
                        >
                          <v-radio :label="$t('Male')" value="male"></v-radio>
                          <v-radio
                            :label="$t('Female')"
                            value="female"
                          ></v-radio>
                        </v-radio-group>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.siblings &&
                            sibling.gender == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.siblings"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong> {{ $t("Mobile Number") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="3" class="noPaddingRight">
                        <div class="code" id="country-code">
                          <v-autocomplete
                            :items="dataObject.Codes"
                            class="form-control"
                            required
                            @change="hideLabel = true"
                            outlined
                            item-text="title"
                            item-value="value"
                            v-model="sibling.country_id"
                            ><template slot="selection" slot-scope="data">
                              <!-- <img src="../../assets/flags/AF.png" />
                                    {{ data.title }} -->

                              <v-flex xs2>
                                <v-avatar size="25px">
                                  <img :src="data.item.icon" />
                                </v-avatar>
                              </v-flex>
                              <v-flex class="ml-4">
                                {{ data.item.title }}
                              </v-flex>
                            </template>
                            <template slot="item" slot-scope="data">
                              <v-list-item-avatar
                                style="
                                  width: 20px;
                                  min-width: 20px;
                                  height: 20px;
                                "
                              >
                                <img :src="data.item.icon" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="data.item.title"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </div>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.siblings &&
                            sibling.country_id == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.siblings"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>

                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          v-model="sibling.mobile"
                          :rules="[
                            mobileRules.required,
                            mobileRules.foramt,
                            mobileRule(sibling.country_id, sibling.mobile),
                          ]"
                          required
                          @focus="onFocus()"
                          solo
                          autocomplete="off"
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.siblings &&
                            sibling.mobile == ''
                          "
                        >
                          <p
                            class="red--text"
                            style="margin-top: -11%"
                            v-for="(error, index) in validation_errors
                              .studentData.siblings"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong> {{ $t("Date of Birth") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="7" class="">
                        <v-text-field
                          :id="'gregorian' + index"
                          autocomplete="off"
                          v-model="sibling.date_of_birth"
                          :rules="validationRules[4].date_of_birth"
                          solo
                          :data-index="index"
                          @keydown.prevent
                          @paste.prevent
                          @drop.prevent
                          append-icon="event"
                          class="dateIcon"
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.siblings &&
                            sibling.date_of_birth == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.siblings"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                        <!-- <v-icon
                          large
                          title="Date of Birth"
                          class="mr-2 main-color dateIcon"
                          >event</v-icon
                        > -->
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" xs="12" md="5">
                        <p>
                          <strong> {{ $t("School") }}:</strong>
                        </p>
                      </v-col>
                      <v-col cols="12" xs="12" md="7" class="">
                        <v-text-field
                          v-model="sibling.school"
                          :rules="validationRules[4].school"
                          required
                          @focus="onFocus()"
                          solo
                          autocomplete="off"
                        ></v-text-field>
                        <div
                          v-if="
                            validation_errors.studentData &&
                            validation_errors.studentData.siblings &&
                            sibling.school == ''
                          "
                        >
                          <p
                            class="red--text"
                            v-for="(error, index) in validation_errors
                              .studentData.siblings"
                            :key="index"
                          >
                            {{ error }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <!-- add_circle -->
      <v-col cols="12" md="3">
        <v-icon
          style="font-size: 30px"
          title="Add Sibling"
          class="mr-2 main-color"
          @click="addSibling"
          >add_circle</v-icon
        >
        {{ $t("Add Siblings") }}
      </v-col>
      <!-- add_circle -->
      <v-col cols="12" xs="12" md="6">
        <v-icon
          style="font-size: 30px"
          title="Add Academic History"
          class="mr-2 main-color"
          @click="addAcademicHistory"
          >add_circle</v-icon
        >
        {{ $t("Add Academic History") }}
      </v-col>
    </v-row>
    <!-- endcard -->
    <v-row
      v-if="
        studentObject.academic_history &&
        studentObject.academic_history.length > 0
      "
    >
      <v-col
        cols="12"
        sm="12"
        md="6"
        class="first-info academic-history"
        v-for="(academic_history, index) in studentObject.academic_history"
        :key="index"
      >
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h3 id="info-header" class="info-header float-left">
                      {{ $t("Academic History") }} {{ parseInt(index + 1) }}
                    </h3>
                    <v-icon
                      large
                      title="Delete Academic History"
                      class="mr-2 main-color float-right"
                      @click="deleteAcademic(index)"
                      >delete</v-icon
                    >
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    id="info-div"
                    class="info-div"
                    style="padding-left: 10px; padding-right: 10px"
                  >
                    <div class="academic_history_container row">
                      <v-col cols="12" xs="12" md="12">
                        <v-row>
                          <v-col cols="12" xs="12" md="5">
                            <p>
                              <strong>{{ $t("School Name") }} :</strong>
                            </p>
                          </v-col>
                          <v-col cols="12" xs="12" md="7" class="">
                            <v-text-field
                              v-model="academic_history.school_name"
                              :rules="validationRules[5].school_name"
                              required
                              @focus="onFocus()"
                              solo
                              autocomplete="off"
                            ></v-text-field>
                            <div
                              v-if="
                                validation_errors.studentData &&
                                validation_errors.studentData
                                  .academic_history &&
                                academic_history.school_name == ''
                              "
                            >
                              <p
                                class="red--text"
                                v-for="(error, index) in validation_errors
                                  .studentData.academic_history"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" xs="12" md="5">
                            <p>
                              <strong> {{ $t("City") }}:</strong>
                            </p>
                          </v-col>
                          <v-col cols="12" xs="12" md="7" class="">
                            <v-autocomplete
                              v-model="academic_history.city"
                              :items="dataObject.city"
                              item-text="name_en"
                              item-value="name_en"
                              :rules="validationRules[5].city"
                              solo
                            ></v-autocomplete>
                            <div
                              v-if="
                                validation_errors.studentData &&
                                validation_errors.studentData
                                  .academic_history &&
                                academic_history.city == ''
                              "
                            >
                              <p
                                class="red--text"
                                v-for="(error, index) in validation_errors
                                  .studentData.academic_history"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" xs="12" md="5">
                            <p>
                              <strong> {{ $t("Level Completed") }}:</strong>
                            </p>
                          </v-col>
                          <v-col cols="12" xs="12" md="7" class="">
                            <v-autocomplete
                              v-model="academic_history.level_completed"
                              :items="dataObject.level_completed"
                              item-text="name"
                              item-value="id"
                              :rules="validationRules[5].level_completed"
                              solo
                            ></v-autocomplete>
                            <div
                              v-if="
                                validation_errors.studentData &&
                                validation_errors.studentData
                                  .academic_history &&
                                academic_history.level_completed == ''
                              "
                            >
                              <p
                                class="red--text"
                                v-for="(error, index) in validation_errors
                                  .studentData.academic_history"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- <hr /> -->
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- endcard -->
    <v-row>
      <v-col cols="12" xs="12" md="6" class="">
        <v-col cols="12" xs="12" md="12">
          <v-checkbox
            dir="rtl"
            :label="$i18n.t('The student repeated a grade in school')"
            class="mx-2"
            v-model="studentObject.repeated_grade"
            @change="repeatedGradeOther()"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
            v-model="studentObject.repeated_grade_name"
            @focus="onFocus()"
            autocomplete="off"
            solo
            v-if="studentObject.repeated_grade"
            :rules="repeated_grade_name"
          ></v-text-field>
          <!-- <div
            v-if="
              validation_errors.studentData &&
                validation_errors.studentData.repeated_grade_name
            "
          >
            <p
              class="red--text"
              v-for="(error, index) in validation_errors.studentData
                .repeated_grade_name"
              :key="index"
            >
              {{ error }}
            </p>
          </div> -->
        </v-col>
      </v-col>
      <v-col cols="12" xs="12" md="6" class="">
        <v-col cols="12" xs="12" md="12">
          <v-checkbox
            dir="rtl"
            :label="
              $i18n.t('The student has been expelled or asked to leave school')
            "
            class="mx-2"
            v-model="studentObject.applicant_expelled"
            @change="applicantExpelledOther()"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" xs="12" md="12">
          <v-text-field
            v-model="studentObject.applicant_expelled_details"
            @focus="onFocus()"
            autocomplete="off"
            solo
            v-if="studentObject.applicant_expelled"
            :rules="applicant_expelled_details"
          ></v-text-field>
          <!-- <div
            v-if="
              validation_errors.studentData &&
                validation_errors.studentData.applicant_expelled_details
            "
          >
            <p
              class="red--text"
              v-for="(error, index) in validation_errors.studentData
                .applicant_expelled_details"
              :key="index"
            >
              {{ error }}
            </p>
          </div> -->
        </v-col>
      </v-col>
    </v-row>

    <v-row class="py-2">
      <v-col cols="12" sm="12" class="first-info">
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h3 id="info-header" class="info-header">
                      {{ $t("Medical History") }}
                    </h3>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    id="info-div"
                    class="info-div"
                    style="padding-left: 10px; padding-right: 10px"
                  >
                    <v-row>
                      <h5
                        style="
                          color: #7297ff;
                          padding-left: 25px;
                          padding-right: 25px;
                        "
                      >
                        {{ $t("Communicable Diseases") }}
                      </h5>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        xs="4"
                        md="2"
                        v-for="(
                          item, index
                        ) in dataObject.communicable_diseases"
                        :key="index"
                        class="diseases"
                      >
                        <v-checkbox
                          class="mx-2"
                          v-model="studentObject.communicable_diseases"
                          :value="item.id"
                        >
                          <template slot="label">
                            <label style="display: block" for="">{{
                              item.name_en
                            }}</label>
                            <label class="name_ar">{{ item.name_ar }}</label>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <br />
                    <v-row>
                      <h5
                        style="
                          color: #7297ff;
                          padding-left: 25px;
                          padding-right: 25px;
                        "
                      >
                        {{ $t("Other Diseases") }}
                      </h5>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        xs="4"
                        md="2"
                        v-for="(item, index) in dataObject.other_diseases"
                        :key="index"
                        class="diseases"
                      >
                        <v-checkbox
                          class="mx-2"
                          v-model="studentObject.other_diseases"
                          :value="item.id"
                        >
                          <template slot="label">
                            <label style="display: block" for="">{{
                              item.name_en
                            }}</label>
                            <label class="name_ar">{{ item.name_ar }}</label>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- endcard -->

    <v-row class="py-2">
      <v-col cols="12" sm="12" md="6" class="first-info">
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <h3 id="info-header" class="info-header">
                      {{ $t("How did you know about us ?") }}
                    </h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="knowaboutus">
                    <v-radio-group
                      v-model="studentObject.know_about_us"
                      row
                      @change="konwaboutOther()"
                    >
                      <v-radio value="socialmedia" label="Social Media">
                        <template slot="label">
                          <label for="" style="margin-top: 0"
                            >{{ $t("Social Media") }}
                            <i
                              class="fab fa-facebook"
                              style="margin-left: 5px; color: #7295fb"
                            ></i
                          ></label>
                        </template>
                      </v-radio>
                      <v-radio value="internet" label="Internet">
                        <template slot="label">
                          <label for="" style="margin-top: 0"
                            >{{ $t("Internet") }}
                            <i
                              class="fab fa-internet-explorer"
                              style="margin-left: 5px; color: #7295fb"
                            ></i
                          ></label>
                        </template>
                      </v-radio>
                      <v-radio value="friends" label="Friends">
                        <template slot="label">
                          <label for="" style="margin-top: 0"
                            >{{ $t("Friends") }}
                            <i
                              class="fas fa-users"
                              style="margin-left: 5px; color: #7295fb"
                            ></i>
                          </label>
                        </template>
                      </v-radio>
                      <v-radio value="family" label="Family">
                        <template slot="label">
                          <label for="" style="margin-top: 0"
                            >{{ $t("Family") }}
                            <i
                              class="fas fa-home"
                              style="margin-left: 5px; color: #7295fb"
                            ></i
                          ></label>
                        </template>
                      </v-radio>
                      <v-radio value="other" label="Other"> </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row v-if="studentObject.know_about_us == 'other'">
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="studentObject.know_about_us_other"
                      @focus="onFocus()"
                      autocomplete="off"
                      solo
                      :rules="know_about_us_other"
                    ></v-text-field>
                    <div
                      v-if="
                        validation_errors.studentData &&
                        validation_errors.studentData.know_about_us_other
                      "
                    >
                      <p
                        class="red--text"
                        v-for="(error, index) in validation_errors.studentData
                          .know_about_us_other"
                        :key="index"
                      >
                        {{ error }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" xs="12" md="6" class="first-info">
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" xs="3" md="1">
                    <v-checkbox
                      class="mx-2"
                      style="margin-top:-4px !important;"
                      v-model="studentObject.participate_voluntary"
                      @change="participateFieldInput"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" xs="12" md="11">
                    <h3 id="info-header" class="info-header">
                      Would you like to participate ?
                    </h3>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row :hidden="participateField">
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="studentObject.participate_voluntary_details"
                  @focus="onFocus()"
                  autocomplete="off"
                  solo
                  :v-if="studentObject.participate_voluntary"
                  :rules="participate_voluntary_details"
                ></v-text-field>
                <div
                  v-if="
                    validation_errors.studentData.participate_voluntary_details
                  "
                >
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.studentData
                      .participate_voluntary_details"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col
        cols="12"
        justify="center"
        align="center"
        class="submit-div text-center"
      >
        <v-btn
          id="submit"
          type="submit"
          class="ui-btn modal-btn-cancel"
          @click.prevent="cancel"
          large
        >
          {{ $t("Cancel") }}
        </v-btn>

        <v-btn
          type="submit"
          @click.prevent="submitUpdateData"
          v-if="editMode"
          class="ui-btn submit"
          :disabled="loading"
          :loading="loading"
          large
        >
          {{ $t("Save") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ snackText }}
      </span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </v-form>
</template>

<script>
import axios from "axios";

import { CompleteProfileValidationsMixin } from "../../mixins/StudentProfile/CompleteProfileValidationsMixin";
// import { validationMixin } from "../../mixins/validationMixin";
import { mapGetters } from "vuex";
export default {
  mixins: [CompleteProfileValidationsMixin],
  props: ["editMode"],
  computed: {
    ...mapGetters(["getBackEndUrl"]),
  },
  data() {
    return {
      loading: false,
      mobileRules: {
        required: (v) => !!v || this.$i18n.t("This field is required"),
        foramt: (v) =>
          /^(\+?)[0-9]+$/.test(v) ||
          this.$i18n.t("Please enter a valid format"),
      },
      lazy: false,
      otherContactRule: [],
      otherParentRule: [],
      repeated_grade_name: [],
      applicant_expelled_details: [],
      participate_voluntary_details: [],
      know_about_us_other: [],
      validation_errors: {
        applicantData: {
          contact_person: "",
          national_id: "",
          passport_no: "",
          iqama_no: "",
          iqama_expiry_date: "",
        },
        studentData: {
          parents_relationship_other: "",
          mother: {
            firstname: "",
            middlename: "",
            familyname: "",
            mobile: "",
            email: "",
            tel: "",
            occupation: "",
            business_address: "",
            education_level: "",

            country_id: "",
          },
          father: {
            firstname: "",
            middlename: "",
            familyname: "",
            mobile: "",
            email: "",
            tel: "",
            occupation: "",
            business_address: "",
            education_level: "",

            country_id: "",
          },
        },
      },
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 3000,
      participateField: false,
      otherParentRelationShip: false,
      logoError: "",
      gradeError: false,
      languages: [
        { key: "English", value: 1 },
        { key: "Arabic", value: 2 },
      ],
      religions: [
        { key: "Muslim", value: "muslim" },
        { key: "Non-muslim", value: "non-muslim" },
      ],
      saudi: "",
      student_id: "",
      // editMode: true,
      valid: false,
      studentObject: [],
      applicantObject: [],
      dataObject: {
        Codes: [],
        nationalities: [],
        religion: [
          {
            key: "Muslim",
            value: "muslim",
          },
          {
            key: "Non Muslim",
            value: "non-muslim",
          },
        ],
        level_completed: [],
        city: [],
        communicable_diseases: [],
        other_diseases: [],
        grades: [],
        other: false,
      },
      father_saudi: false,
      mother_saudi: false,
    };
  },
  watch: {
    editMode: {
      handler() {
        if (this.editMode == true) {
          this.fatherNationalityOnSelect();
          this.motherNationalityOnSelect();
          console.log("editMode");
        }
      },
    },
  },
  methods: {
    fatherNationalityOnSelect() {
      if (this.studentObject.father.nationality_id == 187) {
        this.father_saudi = true;
        this.studentObject.father.parent_iqama_no = "";
        this.studentObject.father.parent_iqama_no_expire_date = "";
      } else {
        this.activateDatepicker();
        this.father_saudi = false;
        this.studentObject.father.national_id = "";
      }
    },
    motherNationalityOnSelect() {
      if (this.studentObject.mother.nationality_id == 187) {
        this.mother_saudi = true;
        this.studentObject.mother.parent_iqama_no = "";
        this.studentObject.mother.parent_iqama_no_expire_date = "";
      } else {
        this.activateDatepicker2();
        this.mother_saudi = false;
        this.studentObject.mother.national_id = "";
      }
    },
    /* father expiry Iqama */
    activateDatepicker() {
      var _this = this;
      $(document).ready(function () {
        $("#expiry").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateM =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;

              _this.studentObject.father.parent_iqama_no_expire_date = dateM;
            } else {
              _this.studentObject.father.parent_iqama_no_expire_date = "";
            }
          },
        });
      });
    },
    activateDatepicker2() {
      var _this = this;
      $(document).ready(function () {
        $("#motherIqamaexpiry").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateM =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;

              _this.studentObject.mother.parent_iqama_no_expire_date = dateM;
            } else {
              _this.studentObject.mother.parent_iqama_no_expire_date = "";
            }
          },
        });
      });
    },

    mobileRule(country_code, mobile) {
      if (country_code == 187) {
        return !/^((?!0)[0-9]{9})*$/.test(mobile)
          ? this.$i18n.t(
              "This field must be only 9 numbers and not start with 0."
            )
          : true;
      } else {
        if (mobile.length <= 5 || mobile.length >= 15) {
          return this.$i18n.t("Mobile Number must be between 5 to 15 digits.");
        }
        return true;
      }
    },
    submitUpdateData() {
      delete this.studentObject.father.nationality;
      delete this.studentObject.mother.nationality;
      // this.applicantObject = applicantObject;
      // this.studentObject = studentObject;
      if (!this.valid) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Please fill all data first";

        this.$refs.form.validate();
      } else {
        this.loading = true;
        axios
          .post(
            this.getApiUrl + "/students/update/" + this.student_id,
            {
              applicantData: this.applicantObject,
              studentData: this.studentObject,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status.error == false) {
              this.submit(true, "green", response.data.status.message);
            } else {
              this.validation_errors = response.data.status.validation_errors;
              this.snack = true;
              this.snackColor = "red";
              this.snackText = response.data.status.message;
            }
          });
      }
    },
    // participateFieldInput() {
    //   if (this.studentObject.participate_voluntary == 1) {
    //     this.participateField = false;
    //     this.participate_voluntary_details = this.validationRules[9].participate_voluntary_details;
    //   } else {
    //     this.participateField = true;
    //     this.participate_voluntary_details = [];
    //   }
    // },
    /* get all countries */
    getCountries() {
      axios.get(this.getApiUrl + "/getCountries").then((response) => {
        this.dataObject.nationalities = response.data.data;
      });
    },
    onFileSelected(event) {
      document.getElementById("preloader-custom").classList.remove("hidden");
      const fd = new FormData();
      this.selectedFile = event.target.files[0];
      fd.append("image", this.selectedFile);
      axios
        .post(this.getApiUrl + "/uploadFormImage", fd, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          if (response.data.status.error) {
            this.logoError = response.data.status.validation_errors.image[0];
          } else {
            this.studentObject.image = response.data.data.url;
          }
          document.getElementById("preloader-custom").classList.add("hidden");
        });
    },
    repeatedGradeOther() {
      if (this.studentObject.repeated_grade == 1) {
        this.repeated_grade_name = this.validationRules[5].repeated_grade_name;
      } else {
        this.repeated_grade_name = [];
      }
    },
    applicantExpelledOther() {
      if (this.studentObject.applicant_expelled == 1) {
        this.applicant_expelled_details =
          this.validationRules[5].applicant_expelled_details;
      } else {
        this.applicant_expelled_details = [];
      }
    },
    konwaboutOther() {
      if (this.studentObject.know_about_us == "other") {
        this.know_about_us_other = this.validationRules[9].know_about_us_other;
      } else {
        this.know_about_us_other = [];
      }
    },
    OnotherPrentRelationship() {
      if (
        this.studentObject.parents_relationship == "other" ||
        (this.studentObject.parents_relationship != "married" &&
          this.studentObject.parents_relationship != "divorced" &&
          this.studentObject.parents_relationship != null)
      ) {
        this.otherParentRelationShip = false;
        this.studentObject.parents_relationship = "other";
        this.otherParentRule =
          this.validationRules[3].parents_relationship_other;
      } else {
        this.otherParentRelationShip = true;
        this.studentObject.parents_relationship_other = "";
        this.otherParentRule = [];
      }
    },
    onOther() {
      if (
        this.applicantObject.contact_person.relation_ship == "other" ||
        (this.applicantObject.contact_person.relation_ship != "father" &&
          this.applicantObject.contact_person.relation_ship != "mother" &&
          this.applicantObject.contact_person.relation_ship != "uncle")
      ) {
        this.other = false;
        this.applicantObject.contact_person.relation_ship = "other";
        this.otherContactRule =
          this.validationRulesApplicant.contact_person_relation_ship_other;
      } else {
        this.other = true;
        this.applicantObject.contact_person.relation_ship_other = "";
        this.otherContactRule = [];
      }
    },

    onChangeNationality() {
      if (this.applicantObject.nationality == 187) {
        this.saudi = true;
        this.applicantObject.passport_no = "";
        this.applicantObject.iqama_no = "";
        this.applicantObject.iqama_expiry_date = "";
      } else {
        this.saudi = false;
        this.applicantObject.national_id = "";
        let _this = this;
        $(document).ready(function () {
          $("#iqamaDate").calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            onSelect: function (date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                _this.applicantObject.iqama_expiry_date = dateH;
              } else {
                _this.applicantObject.iqama_expiry_date = "";
              }
            },
          });
        });
      }
    },
    checkGrade() {
      if (this.applicantObject.grade_level != "") {
        //checkGradeOpen
        axios
          .post(this.getApiUrl + "/checkGradeOpen", {
            alias: "active",
            grade_id: this.applicantObject.grade_level,
          })
          .then((response) => {
            this.gradeError = !response.data.data.open;
          });
      } else {
        this.gradeError = false;
      }
    },
    submit(snack, snackColor, snackText) {
      this.editMode = false;
      this.$emit(
        "submitUpdate",
        this.applicantObject,
        this.studentObject,
        false,
        snack,
        snackColor,
        snackText
      );
    },
    cancel() {
      this.editMode = false;
      this.$emit("cancelEdit");
    },
    onFocus() {
      this.gradeError = false;
      this.validation_errors = {
        applicantData: {},
        studentData: {},
      };
      // this.$refs.form.resetValidation();
    },
    addSibling() {
      this.studentObject.siblings.push({
        name: "",
        gender: "",
        mobile: "",
        country_id: 187,
        date_of_birth: "",
        school: "",
      });
      console.log(this.studentObject.siblings);
      var _this = this;
      this.studentObject.siblings.forEach((item, index) => {
        $(document).ready(function () {
          $("#gregorian" + index).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            maxDate: 0,
            onSelect: function (date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                _this.studentObject.siblings[index].date_of_birth = dateH;
              } else {
                _this.studentObject.siblings[index].date_of_birth = "";
              }
            },
          });
        });
      });
    },
    addAcademicHistory() {
      this.studentObject.academic_history.push({
        school_name: "",
        city: "",
        level_completed: "",
        levels_attended: [],
      });
    },
    deleteSibling(index) {
      if (this.studentObject.siblings[index].id) {
        axios
          .get(
            this.getApiUrl +
              "/students/deleteSiblings/" +
              this.student_id +
              "/" +
              this.studentObject.siblings[index].id,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error) {
              this.snack = true;
              this.snackColor = "error";
              this.snackText = response.data.status.message;
            } else {
              this.studentObject.siblings.splice(index, 1);
              this.snack = true;
              this.snackColor = "green";
              this.snackText = response.data.status.message;
              // this.window.reload();
              // window.location.reload();
            }
          });
      } else {
        this.studentObject.siblings.splice(index, 1);
      }
    },
    deleteAcademic(index) {
      if (this.studentObject.academic_history[index].id) {
        axios
          .get(
            this.getApiUrl +
              "/students/deleteAcademicHistory/" +
              this.student_id +
              "/" +
              this.studentObject.academic_history[index].id,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error) {
              this.snack = true;
              this.snackColor = "error";
              this.snackText = response.data.status.message;
            } else {
              this.studentObject.academic_history.splice(index, 1);
              this.snack = true;
              this.snackColor = "green";
              this.snackText = response.data.status.message;
            }
          });
      } else {
        this.studentObject.academic_history.splice(index, 1);
      }
    },

    // login as User
    loginAsUser(userId) {
      axios
        .get(this.getApiUrl + "/impersonate/" + userId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            let id = response.data.data.user.id;
            let token = response.data.data.access_token;
            let school = response.data.data.school;
            let logo = response.data.data.logo;
            let school_name = response.data.data.school_name;
            localStorage.user_name = response.data.data.user.name;
            // localStorage to store user cradentials
            localStorage.id = id;
            localStorage.email = response.data.data.user.email;
            localStorage.lastLogin = response.data.data.lastLogin;
            localStorage.login = true;
            localStorage.token = token;
            localStorage.school = school;
            localStorage.logo = logo;
            localStorage.school_name = school_name;
            localStorage.expiry = new Date().getTime() + 86400000; // 24 hours
            // localStorage.expiry = new Date().getTime() + 60000; // 1 minute
            localStorage.permissionsObject = JSON.stringify(
              response.data.data.permissions
            );
            localStorage.menus = JSON.stringify(response.data.data.menus);
            localStorage.roles = JSON.stringify(response.data.data.roles);
            if (
              response.data.data.roles &&
              response.data.data.roles.length == 1
            ) {
              localStorage.selectedRole = response.data.data.roles[0];
            } else {
              localStorage.selectedRole = "";
            }
            localStorage.selectedSomeRole = false;
            localStorage.loginAs = response.data.data.login_as;
            if (localStorage.loginAs) {
              localStorage.old_user = response.data.data.old_user;
              localStorage.old_user_name = response.data.data.old_user_name;
            }
            localStorage.updateData = response.data.data.updateData;
            this.$router.push({
              path: `/`,
            });
            window.location = "/";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.student_id = this.$router.currentRoute.params.student_id;
    axios
      .get(this.getApiUrl + "/students/edit/" + this.student_id, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        if (response.data.status.error) {
          window.location = "/";
        } else {
          this.applicantObject = response.data.data.applicantObject;
          this.studentObject = response.data.data.studentObject;
          console.log(this.studentObject);
          axios
            .get(
              this.getApiUrl +
                "/getGrades?student_year=" +
                this.studentObject.year_id
            )
            .then((response) => {
              this.dataObject.grades = response.data.data.grades;
            });
          this.applicantObject.contact_person.communication_method =
            this.applicantObject.contact_person.communication_method.split(",");
          this.onOther();
          this.OnotherPrentRelationship();
          this.fatherNationalityOnSelect();
          // this.participateFieldInput();
          if (this.applicantObject.nationality == 187) {
            this.saudi = true;
          } else {
            this.saudi = false;
          }
          let _this = this;
          $(document).ready(function () {
            $("#gregorianbirth").calendarsPicker({
              dateFormat: "dd/mm/yyyy",
              maxDate: 0,
              onSelect: function (date) {
                if (date[0]) {
                  var dateH =
                    date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                  _this.applicantObject.date_of_birth = dateH;
                } else {
                  _this.applicantObject.date_of_birth = "";
                }
              },
            });
          });
          $(document).ready(function () {
            $("#hijri").calendarsPicker({
              dateFormat: "dd/mm/yyyy",
              maxDate: 0,
              calendar: $.calendars.instance("islamic"),
              onSelect: function (date) {
                if (date[0]) {
                  var dateH =
                    date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                  _this.applicantObject.date_of_birth_hijri = dateH;
                } else {
                  _this.applicantObject.date_of_birth_hijri = "";
                }
              },
            });
          });
          $(document).ready(function () {
            $("#iqamaDate").calendarsPicker({
              dateFormat: "dd/mm/yyyy",
              onSelect: function (date) {
                if (date[0]) {
                  var dateH =
                    date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                  _this.applicantObject.iqama_expiry_date = dateH;
                } else {
                  _this.applicantObject.iqama_expiry_date = "";
                }
              },
            });
          });
          this.studentObject.siblings.forEach((item, index) => {
            $(document).ready(function () {
              $("#gregorian" + index).calendarsPicker({
                dateFormat: "dd/mm/yyyy",
                maxDate: 0,
                onSelect: function (date) {
                  if (date[0]) {
                    var dateH =
                      date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                    _this.studentObject.siblings[index].date_of_birth = dateH;
                  } else {
                    _this.studentObject.siblings[index].date_of_birth = "";
                  }
                },
              });
            });
          });
        }
      });

    axios.get(this.getApiUrl + "/getCodes").then((response) => {
      this.dataObject.Codes = response.data.data;
    });

    axios.get(this.getApiUrl + "/getCountries").then((response) => {
      this.dataObject.nationalities = response.data.data;
    });

    axios.get(this.getApiUrl + "/getCities").then((response) => {
      this.dataObject.city = response.data.data.cities;
    });

    axios.get(this.getApiUrl + "/getGrades").then((response) => {
      this.dataObject.level_completed = response.data.data.grades;
    });

    axios.get(this.getApiUrl + "/students/getDiseases").then((response) => {
      this.dataObject.communicable_diseases =
        response.data.data.communicable_diseases;
      this.dataObject.other_diseases = response.data.data.other_diseases;
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

.v-application p {
  margin-bottom: 0 !important;
}

#wrapper.active {
  padding-left: 200px !important;
}
.icon {
  color: #7297ff !important;
  cursor: pointer;
}
.custom-file {
  width: 70%;
  border: 2px dashed #bbbbbb;
  box-shadow: unset !important;
  margin-left: -2px !important;
  .custom-file-label {
    box-shadow: unset !important;
    border: unset;
    background-color: unset !important;
    &::after {
      display: inline;
      padding: 3px 2;
      background-color: unset !important;
      color: #7297ff;
      margin-right: 36%;
    }
  }
}
.logo-div-img {
  margin-top: 15%;
}
.red--text {
  margin-top: -5%;
}
// .otherParent {
//   margin-right: 5% !important;
//   margin-top: -11% !important;
// }
.float-left {
  float: left;
}
.deleteBtn {
  float: right;
  cursor: pointer;
}
.clear-fix {
  clear: both;
}

.noPaddingRight {
  padding-right: 0 !important;
}
.first-info .v-card {
  height: 100%;
}
.academic-history .v-card {
  height: auto !important;
}
</style>
